<template>
  <div class="rich-text-block">
    <div v-html="renderRichText(body)" />
  </div>
</template>
<script>
import RichText from '../mixins/RichText';
export default {
  name: 'RichTextBlock',
  mixins: [RichText],
  props: {
    body: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped>
 .rich-text-block {
    ::v-deep  {
        p {
          margin: 0;
        }
        p:empty:after {
          content: '\200b';
        }
        a {
          text-decoration: underline;
        }
        ul {
          margin-left: 15px;
          li {
            list-style-type: initial;
          }
        }
        ol {
          margin-left: 15px;
          li {
            list-style-type: initial;
          }
        }
    }
}
</style>
