import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export function getHrefLangLinks (subLink = '') {
  const storeView = currentStoreView();
  const STORE_PLACEHOLDER = '{STORE}'
  const domain = config.server.isProduction ? 'https://www.lovebonito.com' : 'https://sit.lovebonito.com';
  let us_sublink = subLink;
  let rest_sublink = subLink;
  if (subLink.indexOf('about-us') >= 0) {
    if (storeView?.storeCode === 'us') {
      rest_sublink = `/global/about-us`
    } else {
      us_sublink = `/pages/us-about-us`
    }
  }
  const saleRegex = /^\/sale-(\w+)/;
  const match = subLink.match(saleRegex);
  if (match) {
    rest_sublink = `/sale-${STORE_PLACEHOLDER}${subLink.slice(match[0].length)}`;
    us_sublink = rest_sublink
  }

  try {
    return [
      { rel: 'alternate', hreflang: 'x-default', href: `${domain}/intl${rest_sublink.replace(STORE_PLACEHOLDER, 'intl')}` },
      { rel: 'alternate', hreflang: 'en-sg', href: `${domain}/sg${rest_sublink.replace(STORE_PLACEHOLDER, 'sg')}` },
      { rel: 'alternate', hreflang: 'en-my', href: `${domain}/my${rest_sublink.replace(STORE_PLACEHOLDER, 'my')}` },
      { rel: 'alternate', hreflang: 'en-us', href: `${domain}/us${us_sublink.replace(STORE_PLACEHOLDER, 'us')}` },
      { rel: 'alternate', hreflang: 'en-hk', href: `${domain}/hk${rest_sublink.replace(STORE_PLACEHOLDER, 'hk')}` },
      { rel: 'alternate', hreflang: 'zh-HK', href: `${domain}/hk${rest_sublink.replace(STORE_PLACEHOLDER, 'hk')}` },
      { rel: 'alternate', hreflang: 'en-id', href: `${domain}/id${rest_sublink.replace(STORE_PLACEHOLDER, 'id')}` },
      { rel: 'alternate', hreflang: 'ms-MY', href: `${domain}/my${rest_sublink.replace(STORE_PLACEHOLDER, 'my')}` },
      { rel: 'alternate', hreflang: 'id-ID', href: `${domain}/id${rest_sublink.replace(STORE_PLACEHOLDER, 'id')}` },
      { rel: 'alternate', hreflang: 'ph-ph', href: `${domain}/ph${rest_sublink.replace(STORE_PLACEHOLDER, 'ph')}` },
      { rel: 'alternate', hreflang: 'en-ph', href: `${domain}/ph${rest_sublink.replace(STORE_PLACEHOLDER, 'ph')}` }
    ]
  } catch {
    return []
  }
}
