<template>
  <div
    v-if="sortedHomePageBlocks.length"
    id="cms-layout"
  >
    <component
      :is="item.type"
      v-for="(item, index) in sortedHomePageBlocks"
      :key="index"
      v-bind="item.props"
      :observe-section="trackForSections.includes(item.props.blockId)"
    />
    <ClientOnly>
      <MFooterLoyaltySectionNew />
    </ClientOnly>
    <MSeoFooterBlock class="seo-footer-section" :page-type="seoBlockIdenifier" />
  </div>
</template>

<script>
import isOnPage from 'common/mixins/isOnPage.js';
import CmsRender from 'src/modules/storyblok/mixins/CmsRender';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { htmlDecode } from '@vue-storefront/core/filters';
import SetupGtmEvents from 'src/modules/aws-personalize/mixins/SetupGtmEvents';
import cloneDeep from 'lodash-es/cloneDeep';
import InstantiatePosMixin from 'src/common/mixins/InstantiatePosMixin.js';
import { getHrefLangLinks } from 'src/common/helpers/metaDataGenerator';
import MSeoFooterBlock from 'common/components/molecules/footer/m-seo-footer';
import config from 'config'
import ClientOnly from 'vue-client-only';

const SEO_BLOCK_IDENTIFIER = 'home'

export default {
  name: 'HomeContainer',
  components: {
    MSeoFooterBlock,
    ClientOnly,
    MFooterLoyaltySectionNew: () => import('common/components/molecules/footer/m-footer-loyalty-section-new')
  },
  mixins: [isOnPage, CmsRender, SetupGtmEvents, InstantiatePosMixin],
  data: () => ({
    storeName: currentStoreView().storeCode,
    // Block Ordering : Specify the block type along with order index
    homePageContentOrder: {
      homepage_hero_carousel: 0,
      homepage_campaign_block: 1,
      homepage_product_carousel: 2,
      homepage_highlights: 3,
      homepage_about_brand: 4,
      homepage_ugc: 5,
      homepage_lb_benefits: 6
    },
    seoBlockIdenifier: SEO_BLOCK_IDENTIFIER,
    trackForSections: ['homepage_usp_block', 'homepage_hero2']
  }),
  computed: {
    sortedHomePageBlocks () {
      if (this.currentStory.components) {
        const homePageBlocks = cloneDeep(this.currentStory.components);
        return homePageBlocks.sort(
          (a, b) =>
            this.homePageContentOrder[a?.props?.blockId] -
            this.homePageContentOrder[b?.props?.blockId]
        );
      }
      return []
    }
  },
  async asyncData ({ store, route, ...rest }) {
    const { context: ssrContext } = rest;
    const version = route.query?._storyblok ? 'draft' : 'published';
    await store.dispatch('cache-tags/initTag', { set: ssrContext?.output?.cacheTags });
    const homePageBlocks = store.dispatch('storyblok/fetchCmsData', {
      route: route,
      version: version,
      isHome: true
    });

    const seoFooterData = store.dispatch('storyblok/fetchSeoFooterDataHome', { version: version, blockIdentifier: SEO_BLOCK_IDENTIFIER })
    await Promise.all([homePageBlocks, seoFooterData])
  },
  beforeMount () {
    this.$bus.$on('home-block-item-clicked', this.trackGtmEvent);
    this.$bus.$on('homepage_click', this.trackHomeClickGtmEvent);
    this.$bus.$on('home-block-promo-view', this.trackPromoViewEvent);
  },
  beforeDestroy () {
    this.$bus.$off('home-block-item-clicked', this.trackGtmEvent);
    this.$bus.$off('homepage_click', this.trackHomeClickGtmEvent);
    this.$bus.$off('home-block-promo-view', this.trackPromoViewEvent);
  },
  methods: {
    trackHomeClickGtmEvent (data) {
      if (!data) return
      this?.$gtm?.trackEvent({
        event: 'homepage_click',
        ecommerce: data
      })
    },
    trackGtmEvent (data) {
      this.$gtm?.trackEvent({ ecommerce: null });
      this.$gtm?.trackEvent({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [data]
          }
        }
      });
    },
    trackPromoViewEvent (data) {
      this.$gtm?.trackEvent({
        ecommerce: {
          promoView: {
            promotions: [data]
          }
        }
      });
    }
  },
  metaInfo () {
    const storeCode = currentStoreView()?.storeCode

    return {
      title: htmlDecode(this.pageTitle),
      link: [
        {
          rel: 'canonical',
          href: `https://www.lovebonito.com/${storeCode}`
        },
        ...getHrefLangLinks()
      ],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: htmlDecode(this.pageDescription)
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: htmlDecode(this.pageKeywords)
        }
      ],
      script: [
        {
          id: 'schemaMarkup',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            'name': 'Love Bonito ' + config.storeViews[storeCode].name,
            'url': 'https://www.lovebonito.com/' + storeCode,
            'logo': 'https://www.lovebonito.com/assets/favicons/icon256x256.png',
            'sameAs': [
              'https://www.facebook.com/lovebonito/',
              'https://www.instagram.com/lovebonito/?hl=en',
              'https://www.lovebonito.com/' + storeCode
            ]
          }
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#cms-layout {
  margin-top: 170px;
  background: var(--white);
  @include for-mobile {
    margin-top: 106px;
  }
  min-height: 1000px;
  .seo-footer-section {
      padding: 64px 80px;
      @include for-mobile {
        padding: 40px 20px;
      }
      ::v-deep .container {
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
      }
  }
  ::v-deep .loyalty-footer {
    margin-top: 80px;
    @include for-mobile {
      margin-top: 40px;
    }
  }
}
</style>
