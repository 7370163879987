<template>
  <div
    v-if="pageType && shouldRenderWhenNoContent"
    class="full-width-container"
  >
    <div class="container">
      <div
        :class="'content-wrapper '+wrapperStyle"
        ref="infoBox"
      >
        <RichTextBlock v-bind="currentBlock[0].props" />
      </div>
      <a
        class="read-more"
        v-if="!contentExpanded"
        @click.prevent="expandContent"
      >
        READ MORE
      </a>
    </div>
  </div>
</template>

<script>
import RichTextBlock from 'src/modules/storyblok/components/RichTextBlock.vue';
import CmsRender from 'src/modules/storyblok/mixins/CmsRender';
import { mapGetters } from 'vuex';
import windowSize from 'common/mixins/windowSize';

export default {
  name: 'MSeoFooterBlock',
  mixins: [CmsRender, windowSize],
  components: {
    RichTextBlock
  },
  props: {
    pageType: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      contentExpanded: false,
      height: 0,
      wrapperStyle: ''
    }
  },
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    ...mapGetters({
      getStoriesByKey: 'storyblok/getStoriesByKey',
      getPlpFooterContent: 'catalog-es/getPlpFooterContent'
    }),
    currentBlock () {
      return this.blockData?.components || []
    },
    blockData () {
      if (this?.pageType === 'home') {
        const blockKey = `${this.getCurrentStoreView?.storeCode}-seo-footer-home`
        return this.getStoriesByKey(blockKey)?.story || {};
      } else {
        return this.getPlpFooterContent
      }
    },
    shouldRenderWhenNoContent () {
      return this.blockData?.components?.[0]?.props?.body?.content?.length > 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.isExpandable()
    }, 1000)
  },
  methods: {
    expandContent () {
      this.contentExpanded = true
      this.wrapperStyle = ''
    },
    isExpandable () {
      let { device, heightThreshold } = this.isMobileDevice ? { device: 'mobile', heightThreshold: 260 } : { device: 'desktop', heightThreshold: 115 }
      let height = this.$refs?.infoBox?.clientHeight
      if (height > heightThreshold) {
        this.wrapperStyle += `short-${device}-height`
        if (!this.contentExpanded) this.contentExpanded = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.full-width-container {
  background-color: var(--white);
  padding-top: 40px; // the h2 is also getting 20px margin-top
  padding-bottom: 50px; // the below element is getting 10px top border
  @include for-mobile {
    padding-top: 12px; // the h2 is also getting 20px margin-top
    padding-bottom: 22px; // the below element is getting 10px top border
  }
}
.container {
  margin: 0 auto;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
  @include for-mobile {
    padding-left: 37px;
    padding-right: 43px;
  }

  ::v-deep .rich-text-block {
    h1,
    h2 {
      font-size: var(--font_size_400);
      line-height: var(--fix_line_height_600);
      letter-spacing: -0.32px;
      @include for-mobile {
        font-size: var(--font_size_300);
        line-height: var(--fix_line_height_500);
        letter-spacing: -0.48px;
      }
    }
  }
}
.read-more {
  font-size: 12px;
  text-decoration: underline;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 18px;
  color: var(--bold-brown-500);
}
.content-wrapper {
  overflow: hidden;
}
.short-mobile-height {
  height: 266px;
}
.short-desktop-height {
  height: 115px;
}
</style>
