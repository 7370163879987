
import { mapGetters, mapActions } from 'vuex';

export default {
  mounted () {
    const queryObject = this.$route.query || {};
    if (!queryObject || !Object.keys(queryObject).length) {
      return
    }

    const { pos_store = '', referral_code = '', name = '', modal = '', token = '', email = '' } = queryObject;

    if (pos_store) {
      this.setPosClient(pos_store);
    }

    if (this.isLoggedIn) {
      return
    }

    this.$nextTick(() => {
      setTimeout(() => {
        if (modal === 'login' || modal === 'signup' || referral_code || (token && email)) {
          this.toggleAuthModal()
        }
      }, 700);
    })

    if (!referral_code) {
      return
    }
    if (this.isReferAFriendEnabled) {
      this.setUpReferralCode(referral_code, name);
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      isReferAFriendEnabled: 'featurehub/isReferAFriendEnabled'
    })
  },
  methods: {
    ...mapActions({
      setPosClient: 'checkout/setPosClient',
      openModal: 'ui/openModal',
      toggleAuthModal: 'user/toggleAuthModal'
    }),
    setUpReferralCode (referralCode, name) {
      this.$store.dispatch('loyalty/setReferralData', {
        referralCode,
        name
      })
    }
  }
};
